body {
    font-family: "open sans",sans-serif;
}

.clear-float {
    clear:both;
}

.highlight {
    color:#007a88;
    font-size:40px;
}

img.display-left {
    float:left;
    margin-right:10px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    margin-left:10px;
    margin-bottom:10px;
}

h1,h2,h3,h4 {
    color:#007a88;
    font-weight:normal;
    font-family: "alwyn-new-web",sans-serif;
}
h1,h2 {
    font-size:36px;
}
.button {
    display:block;
    color:#007a88;
    border:1px solid #007a88;
    border-radius:50px;
    padding:10px;
    max-width:300px;
    margin: 10px auto;
    text-align:center;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
}
.button:hover {
    background-color:#007a88;
    color:#ffffff;
}
.row {
    display:table;
    border-collapse: seperate;
    border-spacing:20px;
    table-layout:fixed;
}
.col {
    display:table-cell;
    vertical-align:top;
    width:1%;
    text-align:left;
}
span.right {
    float:right;
}
div.block-highlight {
    background:#f0f0f0;
    color:#444444;
    padding:20px;
    margin:20px 0;
}
hr {
    border:none;
    border-bottom:1px solid #007a88;
}

@media only screen and (max-width: 700px){
    .col {
        display: block;
        width: 100%;
    }
}